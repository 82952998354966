/* You can add global styles to this file, and also import other style files */

/* Button Styles */

.btn-dribble {
  background-color: #9EAFFF !important;
}

.btn-default {
  background-color: #B1B1B1 !important;
}

/* End of Button Styles */

.navbar.navbar-transparent {
  background-color: #9EAFFF;
}
.main-content.overlap {
  border-top-color: #9EAFFF;
}
