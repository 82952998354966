.user-1 {
  display: block;
  width: 17px;
  height: 17px;
  background-image: url('../../img/icons-orderlink/user-1.svg');
}

.edit-2 {
  display: block;
  width: 17px;
  height: 17px;
  background-image: url('../../img/icons-orderlink/edit-2.svg');
}

.message-circle-1 {
  display: block;
  width: 17px;
  height: 17px;
  background-image: url('../../img/icons-orderlink/message-circle-1.svg');
}

.check-1 {
  display: block;
  width: 17px;
  height: 17px;
  background-image: url('../../img/icons-orderlink/check-1.svg');
}

.check-1-md {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../../img/icons-orderlink/check-1-md.svg');
}

.file-1 {
  display: block;
  width: 17px;
  height: 17px;
  background-image: url('../../img/icons-orderlink/file-1.svg');
}

.file-1-dark {
  display: block;
  width: 17px;
  height: 17px;
  background-image: url('../../img/icons-orderlink/file-1-dark.svg');
}

.file-1-dark-md {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../../img/icons-orderlink/file-1-dark-md.svg');
}

.x-dark {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../../img/icons-orderlink/x-dark.svg');
}

.x {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../../img/icons-orderlink/x.svg');
}

.arrow-right {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../../img/icons-orderlink/arrow-right.svg');
}

.arrow-left-dark {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('../../img/icons-orderlink/arrow-left-dark.svg');
}

.split {
  display: block;
  width: 17px;
  height: 17px;
  background-image: url('../../img/icons-orderlink/split.svg');
}
