x§.wrapper {
  position: relative;
  top: 0;
  height: auto;
  min-height: 100vh;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }
}

.sidebar,
.off-canvas-sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: $ov-suite-primary;
  padding: 0;

  .logo-img {
    width: 42px;
    max-height: 42px;

    img {
      width: 42px;
      top: 16px;
      left: 18px;
      position: absolute;
    }
  }

  .sidebar-wrapper {
    background-color: inherit;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 75px);
    padding-bottom: 100px;
    position: relative;
    width: 260px;
    z-index: 4;

    * {
      margin: 0;
    }

    .dropdown .dropdown-backdrop {
      display: none !important;
    }

    .navbar-form {
      border: none;
    }

    > .nav,
    .user .info {
      [data-toggle='collapse'] ~ div > ul > li > a {
        span {
          display: inline-block;
          @extend .animation-transition-general;
        }

        .sidebar-normal {
          margin: 0;
          position: relative;
          transform: translateX(0);
          opacity: 1;
          white-space: nowrap;
          display: block;
          line-height: 20px;
          z-index: 1;
        }

        .sidebar-mini-icon {
          text-transform: uppercase;
          width: 34px;
          margin-right: 10px;
          margin-left: 0;
          font-size: 12px;
          text-align: center;
          line-height: 20px;
          position: relative;
          float: left;
          z-index: 1;
          display: inherit;
          color: $opacity-5;
        }

        .sidebar-normal {
          text-transform: capitalize;
        }

        i {
          font-size: 17px;
          line-height: 20px;
          width: 26px;
        }
      }
    }
  }

  .navbar-minimize {
    position: absolute;
    right: 20px;
    top: 2px;
    opacity: 1;

    @extend .animation-transition-general;
  }
  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    display: block;

    .caret {
      top: 14px;
      position: absolute;
      right: 10px;
    }

    li {
      > a + div .nav li > a {
        margin-top: 7px;
      }

      > a {
        margin: 10px 15px 0 0;
        color: $white-color;
        display: block;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 12px;
        padding: 10px 8px 10px 23px;
        line-height: 30px;
      }

      .nav > li > a {
        padding: 5px 8px;
      }

      &.active > a,
      &.active > a > i {
        opacity: 1;
        background-color: #33353d;
        border-radius: 0 30px 30px 0;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        opacity: 0.5;
      }

      &.active > a[data-toggle='collapse'] > {
        background-color: transparent;
        box-shadow: $box-shadow;
      }

      &.active > a[data-toggle='collapse'],
      &.active > a[data-toggle='collapse'] i,
      &.active > a[data-toggle='collapse'] ~ div > ul > li.active i,
      &.active > a[data-toggle='collapse'] ~ div > ul > li.active > a {
        //color: $info-color; //!important;

        & + div .nav .active a {
          background-color: transparent;
          box-shadow: none;

          &:after {
            content: '';
            position: absolute;
            width: calc(100% - 5px);
            z-index: 0;
          }
        }
      }

      &.active > a[data-toggle='collapse'] ~ div > .nav {
        margin-top: 0;
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
      font-weight: 600;
      @extend .animation-transition-general;
    }

    i {
      font-size: 24px;
      float: left;
      margin-right: 12px;
      line-height: 30px;
      width: 34px;
      text-align: center;
      //color: $opacity-5;
      position: relative;
    }

    .collapse,
    .collapsing {
      .nav {
        margin-top: 0;
      }
    }
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    //background-size: cover;
    //background-position: center center;

    &:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      background: #ffffff;
      opacity: 1;
    }
  }

  .logo {
    align-content: center;
    align-items: center;
    background-color: inherit;
    display: flex;
    height: 62px;
    justify-content: space-between;
    min-height: 83px;
    position: relative;
    padding: 17px 9px;
    width: 100%;
    z-index: 4;

    .logo-image-large,
    .logo-image-small {
      img {
        height: 48px;
      }
    }

    a.logo-mini,
    a.logo-normal {
      @extend .animation-transition-general;
    }

    a.logo-mini {
      // opacity: 0;
      float: left;
      text-align: center;
      margin-left: 10px;
      //margin-right: 12px;

      .logo-image-large {
        width: 163px;
      }

      .logo-image-small {
        width: 46px;
      }
    }

    a.logo-normal {
      display: block;
      opacity: 1;
      padding: 11px 0 8px;
      @include transform-translate-x(0);
    }

    //&:after {
    //  content: '';
    //  position: absolute;
    //  bottom: 0;
    //  right: 15px;
    //  height: 1px;
    //  width: calc(100% - 30px);
    //  background-color: $opacity-5;
    //}

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: 'Muli', 'Helvetica', Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      display: block;
      white-space: nowrap;
      font-size: $font-size-large;
      color: $white-color;
      text-decoration: none;
      font-weight: $font-weight-normal;
      line-height: 30px;
      overflow: hidden;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &:before,
  &:after {
    display: block;
    content: '';
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:after {
    @include icon-gradient($default-color);
    z-index: 3;
  }

  &[data-color='white'] {
    @include sidebar-color($white-color);
    @include sidebar-text-color($default-color);
  }
  &[data-color='brown'] {
    @include sidebar-color($default-color);
  }

  // Active color changing

  &[data-active-color='primary'] {
    @include sidebar-active-color($primary-color);
  }
  &[data-active-color='info'] {
    @include sidebar-active-color($info-color);
  }
  &[data-active-color='success'] {
    @include sidebar-active-color($success-color);
  }
  &[data-active-color='warning'] {
    @include sidebar-active-color($warning-color);
  }
  &[data-active-color='danger'] {
    @include sidebar-active-color($danger-color);
  }

  .user {
    background: invert($ov-suite-primary, 10%);
    padding: 8px 0 8px;
    margin: 20px auto 0;
    position: relative;

    p {
      color: #fff;
      text-transform: uppercase;
      line-height: 28px;
      margin: 0;
      font-size: 12px;
    }

    .photo {
      width: 42px;
      height: 42px;
      overflow: hidden;
      float: left;
      z-index: 5;
      margin-right: 10px;
      border-radius: 50%;
      margin-left: 20px;
      @extend .animation-transition-general;
      border: 2px #fff solid;
      box-shadow: 0 0 2px 2px #2e30371a;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
      }
    }

    a {
      color: $white-color;
      text-decoration: none;
      padding: $padding-base-vertical 15px;
      white-space: nowrap;
      @extend .animation-transition-general;
    }

    a:hover {
      color: $primary-color;
      opacity: 0.5;
      cursor: pointer;
    }

    .info {
      > a {
        display: block;
        line-height: 18px;

        > span {
          @extend .animation-transition-general;
          display: block;
          position: relative;
          opacity: 1;
        }
      }

      .caret {
        position: absolute;
        top: 8px;
        right: 18px;
      }
    }
  }
}

.visible-on-sidebar-regular {
  display: inline-block !important;
}
.visible-on-sidebar-mini {
  display: none !important;
}

.off-canvas-sidebar {
  .nav {
    > li > a,
    > li > a:hover {
      color: $white-color;
    }

    > li > a:focus {
      background: rgba(200, 200, 200, 0.2);
    }
  }
}

@media (max-width: 991px) {
  .main-panel {
    width: calc(100% - 83px);
  }
}

@media (min-width: 991px) {
  .main-panel {
    width: calc(100% - 260px);
  }
}

.nav-open {
  @media (max-width: 991px) {
    .main-panel {
      width: calc(100%);
    }
  }

  @media (min-width: 991px) {
    .main-panel {
      width: calc(100% - 260px);
    }
  }
}
@media screen {
  .main-panel {
    position: relative;
    float: right;
    //width: $sidebar-width;
    //background-color: #F9FAFB;

    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .main-content {
      //min-height: calc(100vh - 83px);
      height: calc(100vh - 83px);
      background-color: #ffffff;
      margin-top: 83px;
      display: flex;
      flex-direction: column;
    }

    .main-content-overflow {
      min-height: calc(100vh - 83px);
      margin-top: 83px;
      display: flex;
      flex-direction: column;
    }

    .main-container {
      flex: 1;
      padding: 30px;
      width: 100%;
      overflow-y: auto;
      background-color: #f9fafb !important;
    }

    .main-container-no-table {
      flex: 1;
      padding: 10px 30px;
      width: 100%;
      overflow-y: auto;
      background-color: #f9fafb !important;
    }

    .main-container-static {
      flex: 1;
      padding: 30px;
      width: 100%;
      overflow: hidden;
    }

    :not(.main-content).overlap {
      margin-top: -30px;
    }

    .main-content.overlap {
      margin-top: 82px;
      border-top-style: solid;
      border-top-width: 50px;
    }

    .navbar {
      margin-bottom: 0;
    }

    .header {
      margin-bottom: 50px;
    }
  }
}

.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }
}

@media (min-width: 991px) {
  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
  }

  .sidebar-mini {
    .visible-on-sidebar-regular {
      display: none !important;
    }
    .visible-on-sidebar-mini {
      display: inline-block !important;
    }

    .sidebar,
    .sidebar .sidebar-wrapper {
      width: 80px;
    }

    .main-panel {
      width: $sidebar-mini-width;
    }

    .sidebar {
      display: block;
      z-index: 1030;

      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-25px);
        }
        a.logo-mini {
          opacity: 1;
        }
      }

      .sidebar-wrapper {
        > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user .info [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user .info > a > span,
        > .nav li > a p {
          @include transform-translate-x(-25px);
          opacity: 0;
        }
      }
    }

    .sidebar:hover {
      width: 260px;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0);
        }
      }

      .navbar-minimize {
        opacity: 1;
        height: fit-content;
      }
      .sidebar-wrapper {
        width: 260px;

        > .nav li > a p,
        > .nav [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user .info [data-toggle='collapse'] ~ div > ul > li > a .sidebar-normal,
        .user .info > a > span {
          @include transform-translate-x(0);
          opacity: 1;
        }
      }
    }
  }
}

.panel-header {
  height: 260px;
  padding-top: 80px;
  padding-bottom: 45px;
  background: deeppink; /* fallback for old browsers */
  background: -webkit-gradient(linear, left top, right top, from(#0c2646), color-stop(60%, #204065), to(#2a5788));
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  position: relative;
  overflow: hidden;

  .header {
    .title {
      color: $white-color;
    }
    .category {
      max-width: 600px;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 13px;

      a {
        color: $white-color;
      }
    }
  }
}

.panel-header-sm {
  height: 135px;
}

.panel-header-lg {
  height: 380px;
}

// Stying for logos

.sidebar .logo .simple-text .logo-img {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;

  img {
    width: 51%;
    vertical-align: unset;
    height: 55px;
  }
}

.sidebar {
  overflow: hidden !important;
  color: #fff;

  &[data-color='white'] {
    border-right: 1px solid #ddd;
  }
}

.navbar-sub-item-open {
  background: $ov-suite-primary;
  margin: 0 0 1px !important;
  padding: 10px 0 10px 40px !important;
}

.navbar-sub-item-close {
  background: $ov-suite-primary;
  margin: 0 0 1px !important;
  padding: 10px 0 10px 25px !important;
}

.adminlink {
  .logo-image-large {
    height: 48px;
    width: 100%;
    background: url(/assets/img/linksuite/admin/logo-large.svg) no-repeat;
    background-size: contain !important;
  }

  .logo-image-small {
    height: 48px;
    width: 100%;
    background: url(/assets/img/linksuite/admin/logo-small.svg) no-repeat;
    background-size: 38px 48px;
    background-position-x: 2px;
  }

  .nav {
    li {
      &.active > a,
      &.active > a > i {
        color: #cfe3f3;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        color: #cfe3f3;
      }
    }
  }
}

.accountlink {
  .logo-image-large {
    height: 48px;
    width: 100%;
    background: url(/assets/img/linksuite/account/logo-large.svg) no-repeat;
    background-size: contain !important;
  }

  .logo-image-small {
    height: 48px;
    width: 100%;
    background: url(/assets/img/linksuite/account/logo-small.svg) no-repeat;
    background-size: 38px 48px;
    background-position-x: 2px;
  }

  .nav {
    li {
      &.active > a,
      &.active > a > i {
        color: #cfe3f3;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        color: #cfe3f3;
      }
    }
  }
}

.warehouselink {
  .logo-image-large {
    height: 48px;
    width: 100%;
    background: url(../../img/linksuite/warehouse/logo-large.svg) no-repeat;
    background-size: contain !important;
  }

  .logo-image-small {
    height: 48px;
    width: 100%;
    background: url(../../img/linksuite/warehouse/logo-small.svg) no-repeat;
    background-size: 38px 48px;
    background-position-x: 2px;
  }

  .nav {
    li {
      &.active > a,
      &.active > a > i {
        color: #fcd861;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        color: #fcd861;
      }
    }
  }
}

.yardlink {
  background-color: #f9fafb;

  .logo-image-large {
    height: 48px;
    width: 100%;
    background: url(/assets/img/linksuite/yard/logo-large.svg) no-repeat;
    background-size: contain !important;
  }

  .logo-image-small {
    height: 48px;
    width: 100%;
    background: url(/assets/img/linksuite/yard/logo-small.svg) no-repeat;
    background-size: 38px 48px;
    background-position-x: 2px;
  }

  .nav {
    li {
      &.active > a,
      &.active > a > i {
        color: #a9e5ff;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        color: #a9e5ff;
      }
    }
  }
}

.invoicelink {
  .logo-image-large {
    height: 48px;
    width: 100%;
    background: url(/assets/img/linksuite/invoice/logo-large.svg) no-repeat;
    background-size: contain !important;
  }

  .logo-image-small {
    height: 48px;
    width: 100%;
    background: url(/assets/img/linksuite/invoice/logo-small.svg) no-repeat;
    background-size: 38px 48px;
    background-position-x: 2px;
  }

  .nav {
    li {
      &.active > a,
      &.active > a > i {
        color: red;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        color: red;
      }
    }
  }
}

.executionlink {
  .nav {
    li {
      &.active > a,
      &.active > a > i {
        color: red;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        color: red;
      }
    }
  }
}

.orderlink{
  .logo-image-large {
    height: 48px;
    width: 100%;
    background: url(../../img/linksuite/order/logo-large.svg) no-repeat;
    background-size: contain !important;
  }

  .logo-image-small {
    height: 48px;
    width: 100%;
    background: url(../../img/linksuite/order/logo-small.svg) no-repeat;
    background-size: 38px 48px;
    background-position-x: 2px;
  }

  .nav {
    li {
      &.active > a,
      &.active > a > i {
        color: #9eafff;
      }

      &:hover:not(.active) > a,
      &:focus:not(.active) > a {
        color: #9eafff;
      }
    }
  }
}
